import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Frame from "../../components/frame"
import Layout from "../../components/layout"
import Text from "../../components/text"

const Page = () => {
  return (
    <Layout>
      <Frame width="100vw" align="center">
        <Frame
          maxWidth={1000}
          width="100vw"
          direction="vertical"
          align="center"
          gap={4}
          px={1}
          pt={4}
          pb={8}
        >
          {/* Header */}
          <Frame maxWidth={800} align="center" gap={2}>
            <Text>October 14th, 2022</Text>
            <Text
              size="60px"
              weight={300}
              color="#006FB7"
              align="center"
              lineHeight={1}
            >
              Find out more about lung cancer screening
            </Text>
          </Frame>
          {/* Body */}
          {/* <StaticImage
            src="../../images/article.jpg"
            alt=""
            style={{ flex: 1 }}
            objectFit="contain"
          /> */}
          <Frame maxWidth={700} gap={1.2}>
            <Text size="18px" lineHeight={1.5}>
              <Text
                size="22px"
                color="#006FB7"
                weight={500}
                style={{ margin: "1rem 0" }}
              >
                What is lung cancer screening?
              </Text>
              <p>
                Lung cancer screening uses a test called low dose computed
                tomography (LDCT), which examines the chest to identify lung
                cancer at an early stage.
              </p>
              <p>
                Large clinical trials have shown there’s significant benefit in
                performing LDCT screenings on patients with high risk factors.
              </p>
              <Text
                size="22px"
                color="#006FB7"
                weight={500}
                style={{ margin: "2rem 0 1rem 0" }}
              >
                The benefits of lung cancer screening
              </Text>
              <p>
                Screening helps to detect the disease before it becomes
                symptomatic. It is quick and painless, and is more sensitive
                than chest X-rays in lung cancer diagnosis.
              </p>
              <p>
                Recent studies show LDCT reduces lung cancer mortality by up to
                20 per cent.
              </p>
              <Text
                size="22px"
                color="#006FB7"
                weight={500}
                style={{ margin: "2rem 0 1rem 0" }}
              >
                The potential risks of lung cancer screening
              </Text>
              <p>
                But it’s also important to be aware of the potential harms of
                lung cancer screenings.
              </p>
              <p>
                The exposure to ionising radiation, while only in low levels,
                can damage cells and lead to the development of cancer.
                Additionally, false positive results can trigger anxiety and
                cause unnecessary tests and procedures.
              </p>
              <p>
                Despite these potential harms, screening remains an important
                tool for early diagnosis of lung cancer and offers the best
                chance of survival for patients with the disease.
              </p>
              <Text
                size="22px"
                color="#006FB7"
                weight={500}
                style={{ margin: "2rem 0 1rem 0" }}
              >
                Learn more about low-dose CT
              </Text>
              <p>
                A CT scan is a type of X-ray that creates detailed image
                ‘slices’ of organs, bones and other tissues.
              </p>
              <p>
                When compiled on a computer, these slices provide a
                high-quality, 3D picture that allows your care team to detect
                small abnormalities.
              </p>
              <p>
                However, CT scans involve ionising radiation, which can increase
                the risk of future cancers for the patient.
              </p>
              <p>
                So in some cases, doctors request that technicians use the
                lowest dose of radiation possible to capture the image. This is
                called an LDCT.
              </p>
              <p>
                Given that lungs are predominantly filled with air, there is low
                requirement for X-ray energy to acquire the images.
              </p>
              <p>
                This means an LDCT can optimise the images to focus on the lungs
                and therefore only requires a low radiation dose.
              </p>
              <p>
                Compared with other sources of radiation in this graph, LDCT has
                very low exposure to radiation for patients.
              </p>
              <StaticImage
                src="https://res.cloudinary.com/dgmfkwhdi/image/upload/v1667826057/comparison_of_sources_of_radiation_exposure_iijkye.png"
                alt=""
                style={{ flex: 1 }}
                objectFit="contain"
              />
              <Text
                size="22px"
                color="#006FB7"
                weight={500}
                style={{ margin: "2rem 0 1rem 0" }}
              >
                Know your risk
              </Text>
              <p>
                Lung cancer is a very serious disease with a high mortality
                rate.
              </p>
              <p>
                But it’s treatable if it’s caught early enough. Many people with
                lung cancer go on to lead normal, healthy lives.
              </p>
              <p>
                Early detection is key, so stay aware of the lung cancer
                symptoms and see a doctor if you have any concerns.
              </p>

              <p>
                Take our easy-to-use{" "}
                <Link to="/to-quiz" style={{ color: "#006FB7" }}>
                  quiz
                </Link>{" "}
                to determine if your are at risk.
              </p>
            </Text>
          </Frame>
        </Frame>
      </Frame>
    </Layout>
  )
}

export default Page
